/* eslint-disable */
import React, { PureComponent } from 'react';

import Helmet from 'react-helmet';

import seo from 'tools/seo';

import Loading from 'components/Loading';

type Props = {};

class ContentPolicy extends PureComponent<Props> {
  state = { loaded: false };

  onLoad = () => {
    this.setState({ loaded: true });
  };

  render() {
    const { loaded } = this.state;
    // https://magic.reactjs.net/htmltojsx.htm
    return (
      <>
        <Helmet>
          {seo.title('Content policies')}
          {seo.description(
            "Having an open mind doesn't mean that we have no rules. Read carefully, respect others, protect young public. All details in this page.",
          )}
        </Helmet>
        {!loaded && <Loading />}
        <div className={`Page-Webflow ${loaded || 'd-none'}`}>

          
          {/* DEBUT HTML CONTENT POLICY ENGLISH WEBFLOW */}

          {/* This site was created in Webflow. http://www.webflow.com */}
          {/* Last Published: Tue Dec 29 2020 17:44:57 GMT+0000 (Coordinated Universal Time) */}
          <meta charSet="utf-8" />
          <title>Content - ENGLISH</title>
          <meta content="Content - ENGLISH" property="og:title" />
          <meta content="Content - ENGLISH" property="twitter:title" />
          <meta content="Webflow" name="generator" />
          <link
            href="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/css/prices-flowfo.webflow.771c13c24.css"
            onLoad={this.onLoad}
            rel="stylesheet"
            type="text/css"
          />
          {/*[if lt IE 9]><![endif]*/}
          <link href="https://uploads-ssl.webflow.com/img/favicon.ico" rel="shortcut icon" type="image/x-icon" />
          <link href="https://uploads-ssl.webflow.com/img/webclip.png" rel="apple-touch-icon" />
          <div>
            <div className="divdeslangages">
              <a href="https://flowfo.me/fr/content-policy" className="linklanguage w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/5f95ec5bf00d8b10e6b4166c_THREE%20FLAGS.png"
                  alt=""
                  className="drapeauxronds"
                />
                <h2 className="changelangue">lire en français</h2>
              </a>
            </div>
            <div className="divwithcontent">
              <h1 className="webflowheading1">Content policy</h1>
              <h3 className="webflowheading3">Authenticity</h3>
              <p className="webflowparagraphe">
                <strong>
                  Flowfo is intended for creators who offer the world something original of which they are the authors.
                </strong>
                <br />
                We consider satire, caricature and alternative universes to be important creations.
                <br />
                But as a creator, you cannot post work that infringes the rights of other people.
                <br />
                You cannot create fake pages or collect money for something you are not the author of. <br />
                You cannot create a fan page on behalf of someone else.
                <br />
                You cannot represent someone else by using their name, brand or work.
                <br />
                You can't fundraise for not "doing" something. For example: "I'll stop joking about someone if I hit $
                200 per month."
              </p>
              <h3 className="webflowheading3">Bullying, harassment, and threats</h3>
              <p className="webflowparagraphe">
                Flowfo must remain a safe place for all our communities.
                <br />
                We do not allow intimidation, harassment or threat.
                <br />
                There is no room on Flowfo for hate speech, calls for violence, exclusion or segregation.
                <br />
                No information of a private nature (telephone, address ...) and belonging to a third person can be
                disclosed on the platform.
                <br />
                Mood swings are always possible, but recurring insults are prohibited.
              </p>
              <h3 className="webflowheading3 red">Adult content - NSFW</h3>
              <p className="webflowparagraphe">
                NSFW is the abbreviation for "Not Safe For Work", describing "adult content".
                <br />
                <strong>Artworks with adult content should be marked as such</strong> when they feature unusually
                violent content, credibly talking about sex or depicting sex scenes.
                <br />
                Commonly accepted foul language is NOT NSFW.
                <br />
                Any NSFW artwork remains referenced on Flowfo but is subject to an explicit disclaimer.
                <br />‍<br />
                <strong>
                  Banners, covers that identify artworks, user profile pictures can in no way be NSFW as they are
                  visible to all audiences.
                </strong>{' '}
                The representation of the genitals, of gore scenes is thus prohibited on these elements.
                <br />
                When in doubt about the content of an artwork, we advise creators to declare it as NSFW.
                <br />
                As a reminder, it is quite possible to create other artworks intended for different audiences.
              </p>
              <h3 className="webflowheading3 red">Nudity and pornography</h3>
              <p className="webflowparagraphe">
                You can post creations of nude characters, whether real or pictured, but those artworks must be marked
                as NSFW (= with adult content).
                <br />
                Sex scenes are authorized (with NSFW marking) as long as they take place in a story or have an obvious
                artistic point of view (for photos for example).
                <br />
                Pornography (masturbation or sex scenes in front of a camera) is prohibited.
                <br />
                Sexual services and prostitution are prohibited.
              </p>
              <h3 className="webflowheading3 red">Fight against pedophilia</h3>
              <p className="webflowparagraphe">
                The representation of minors involved in sex scenes is strictly prohibited.
                <br />
                This prohibition is valid for all media: photos, videos, drawings, texts, whether the minor is real,
                represented or stylized. This prohibition is also valid if the creator is the subject of the work.
                <br />
                <strong>For manga, the Shotacon or Shota genre is prohibited.</strong>
                <br />
                In order to preserve the safety of minors, we are likely to report to the authorities any content of a
                pedophile nature.
                <br />
              </p>
              <h3 className="webflowheading3 violet">Unusual creations</h3>
              <p className="webflowparagraphe">
                Glorification of violence, rape, cannibalism, extreme gore, incest, necrophilia, urophilia, scatology
                are generally prohibited.
                <br />
                However, as these themes / practices are part of real life, we understand that creators may have to
                address them. <br />
                But this must remain exceptional and contextualized in a more global work.
              </p>
              <h3 className="webflowheading3 violet">Harmful and illegal activities</h3>
              <p className="webflowparagraphe">
                You may not promote illegal activities such as crimes against others or against property, the
                distribution of illegal weapons or the manufacture / sale of drugs.
                <br />
                Pages that promote self-harm, suicide or an eating disorder are prohibited.
                <br />
                All support for terrorist groups is prohibited.
                <br />
                Any funding intended for a political group or a political campaign is prohibited.
                <br />
                Dangerous filmed stunts are prohibited.
              </p>
              <h3 className="webflowheading3">Spam</h3>
              <p className="webflowparagraphe">
                Do not post repetitive comments or send unwanted private messages to other members of the community.
              </p>
              <h3 className="webflowheading3">Accounts violating our content policy</h3>
              <p className="webflowparagraphe">
                We will take action against an account if we find that it is in violation of these guidelines.
                <br />
                These measures include the deletion of files, pitches, descriptions, comments, deletion of Flow, page or
                profile.
                <br />
                We won't share any details related to the type of action we might have taken against an account.
                <br />
                This policy is part of the general conditions of Flowfo.
                <br />
                For more information, contact Flowfo at{' '}
                <a
                  href="mailto:contact@flowfo.me?subject=About%20your%20content%20policies"
                  target="_self"
                  className="webflowlink"
                >
                  contact@flowfo.me
                </a>
                .
              </p>
              <div className="divendblank" />
            </div>
          </div>
          {/*[if lte IE 9]><![endif]*/}

          {/* FIN HTML WEBFLOW CONTENT POLICY in ENGLISH */}
        </div>
      </>
    );
  }
}

export default ContentPolicy;
